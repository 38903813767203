'use client'

import { Suspense, PropsWithChildren } from 'react'

import { useHydration } from '@/common/hooks/useHydration'

export function ReHydrate({ children }: PropsWithChildren) {
  const hydrated = useHydration()

  return (
    <Suspense key={hydrated ? 'hydrated' : 'not-hydrated'}>{children}</Suspense>
  )
}
